ol {
  padding-inline-start: 20px;
}

@media only screen and (min-width: 1170px) {
  .sq-timeline {
    margin-bottom: -15em !important;
  }

  .tq-timeline {
    margin-bottom: -15em !important;
  }
}