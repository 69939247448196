.memorandum-chart {
  width: 98%;
}

.memorandum-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  align-items: center;
}

.memorandum-header {
  padding-top: 1rem;
  text-decoration: underline;
  margin: 0 0 0 0;
  font-size: 30px;
  font-weight: 600;
}

.memorandum-header-label {
  text-decoration: none;
  font-size: 16px;
  text-align: right;
  font-weight: 600;
}

.memorandum-header-value {
  text-decoration: none;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
}

.memorandum-header-row {
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  min-width: 162px;
  max-width: initial;
  margin: 5px;
}

.memorandum-section-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: underline;
  margin: 0 0 0 0;
  width: 100%;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
}

.memorandum-section-header-sub {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: underline;
  margin: 0 0 0 0;
  width: 100%;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
}

.memorandum-section-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  width: 100%;
  font-size: 14px;
  text-align: justify;
}

.memorandum-section-content-bullet {
  text-decoration: none;
  width: 100%;
  font-size: 14px;
  text-align: justify;
}

.memorandum-section-spacer {
  height: 100px;
  text-decoration: none;
  width: 100%;
  font-size: 14px;
  text-align: justify;
}